<template>
	<!--  -->
	<!-- <el-dialog :title="$t('i18nn_2a2e2fbcc567efc6')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1000px" top="0" v-loading="loadingUpload"> -->
	<el-drawer :wrapperClosable="false" :title="title+'-'+$t('i18nn_2a2e2fbcc567efc6')" append-to-body :visible.sync="dialogFile"
		:direction="'rtl'" size="1000px" v-loading="loadingUpload">
		<div style="margin-top: 0px;">
			<el-card>
				<div slot="header" class="">
					<h3>
						<i class="el-icon-folder-opened"></i>
						<span>【{{title}}】</span>
						<span>{{ $t('i18nn_2a2e2fbcc567efc6') }}</span>
					</h3>
				</div>
				<!-- <div style="padding: 10px 0;"> -->
				<!-- <el-radio-group v-model="wh_file_type" size="medium">
					  <el-radio v-for="item in selectOption.wh_file_type" :key="item.code" :label="item.code">
							{{ $Utils.i18nCodeText(item) }}
						</el-radio>
					</el-radio-group> -->
		
				<!-- <el-select filterable clearable size="mini" v-model="form.carTmsType" :placeholder="$t('FormMsg.Please_select')" style="width: 300px;">
					  <el-option v-for="item in selectOption.wh_car_tms_type" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
					</el-select> -->
				<!-- </div> -->
				<HyElWhUpLoad ref="HyElWhUpLoad" :listType="'picture'"
					:showFileList="false" :watchKey="fileWatchKey" :fileListShow="fileList"
					:fileId="fileId" :folder="'storage/attachment/'" :fileKey="fileKey" :bucket="'hytx-oss-wh'" :accept="''"
					:maxSizeMB="50" :limit="20" v-on:UploadSuccessUrl="changeFileUrl"></HyElWhUpLoad>
			</el-card>
		</div>
		<div style="margin-top: 10px;">
			<el-card v-if="tableData && tableData.length > 0" :body-style="{padding:'0'}">
				<div slot="header" class="">
					<h3>
						<i class="el-icon-folder"></i>
						<span>【{{title}}】</span>
						<span>{{ $t('i18nn_e8c8c5ee90b04380') }}</span>
					</h3>
				</div>
				<div>
					<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small">
						<el-table-column type="index" fixed="left" width="50" align="center"
							:label="$t('Storage.tableColumn.no')"></el-table-column>

						<el-table-column prop="typeName" :label="$t('i18nn_184333c81babf2f1')" width="100"></el-table-column>

						<el-table-column prop="fileName" :label="$t('i18nn_1aa9b398f37906a4')"></el-table-column>
						<el-table-column :label="$t('i18nn_2674282277c3714e')" width="130">
							<template slot-scope="scope">
								<el-image style="width: 100px; height: 100px" :z-index="9999" :fit="'contain'" :src="scope.row.url"
									:preview-src-list="tableData.map(itemPre=> { return itemPre.url})">
									<div slot="error" class="image-slot">
										<i class="el-icon-document"></i>
									</div>
								</el-image>
							</template>
						</el-table-column>
						<el-table-column prop="url" :label="$t('i18nn_d54012286fece209')">
							<template slot-scope="scope">
								<a :href="scope.row.url" target="_blank">{{ scope.row.url }}</a>
							</template>
						</el-table-column>
						<el-table-column :label="$t('Storage.tableColumn.operation')" width="200px" fixed="right" align="left">
							<template slot-scope="scope">
								<el-button @click="delAction($event, scope.row)" type="danger" size="mini"
									icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</el-card>
		</div>

		

		<div class="drawer-footer">
			<!-- <el-button type="primary" plain @click="dialogFile = false">{{ $t('FormMsg.Close') }}</el-button> -->
			<el-button type="primary" icon="el-icon-tickets" @click="submitExcelFormAction()">{{ $t('FormMsg.Save') }}</el-button>
		</div>
	</el-drawer>
	<!-- </el-dialog> -->
</template>
<script>
	import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		// props: ['isDialog'],
		props: {
			// mobile:"",
			// isShow: {
			//   default: function() {
			//     return false;
			//   },
			//   type: Boolean
			// },
			// fileId: {
			//     default: function() {
			//       return this.$store.getters.getUserInfo.id;
			//     },
			//     type: String
			//   },
			fileKey: {
				default: function() {
					return 'file';
				},
				type: String
			},
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			relationId: {
				default: function() {
					return '';
				},
				type: String
			},
			title: {},
			typeCode: {}
		},
		components: {
			HyElWhUpLoad
		},
		data() {
			return {
				dialogFile: false,
				loadingUpload: false,
				//附件
				fileWatchKey: '1',
				fileList: [],
				fileId: this.$store.getters.getUserInfo.id,

				wh_file_type: "",

				saveErr: false,

				listUrl: [],

				tableData: [],
				selectOption: {
					wh_file_type: []
				},
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFile = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();

		},
		//编译挂载前
		mounted() {
			// this.getDicData();

			this.initData();
		},
		methods: {
			initData() {
				//初始化上传控件
				this.listUrl = [];
				this.tableData = [];

				this.$nextTick(() => {
					try {
						this.$refs.HyElWhUpLoad.initData();
						this.$refs.HyElWhUpLoad.clearFileList();
					} catch (err) {
						console.log(err);
					}
				});
				if (this.relationId) {
					this.getListData();
				}
			},
			//上传回调
			changeFileUrl(val) {
				console.log('changeFileUrl', val);
				let listUrl = val.map(item => {
					// "filePath":"","fileName"：“”
					return {
						filePath: item.url,
						fileName: item.name
					};
				});
				console.log('listUrl', listUrl);
				// this.$alert('上传成功！共上传 ' + listUrl.length + ' 个附件', this.$t('tips.tipsTitle'), {
				//   type: 'success'
				//   //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				// });
				this.listUrl = listUrl;

				// this.myForm.dataList = listUrl;
				// this.myForm.dataList = val;

				// this.submitExcelFormAction();
				this.submitExcelFormData();
			},
			submitExcelFormAction() {
				if (this.saveErr) {
					//保存到后台有误时
					this.submitExcelFormData();
				} else {
					//重新执行-oss 文件上传及回调
					this.$nextTick(() => {
						try {
							this.$refs.HyElWhUpLoad.submitUpload();
						} catch (err) {
							console.log(err);
						}
					});
				}
			},
			submitExcelFormData() {
				let formData = [];
				this.listUrl.forEach(item => {
					if (item.fileName && item.filePath) {
						formData.push({
							// id: this.relationId,
							type: this.typeCode,
							fileName: item.fileName,
							url: item.filePath
						});
					}
				});
				this.submitExcelForm(formData);
			},
			//提交-
			submitExcelForm(formData) {
				this.loadingUpload = true;
				this.$http
					.put(this.$urlConfig.WhFileUploadAdd + '/' + this.relationId, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						this.loadingUpload = false;
						if (200 == data.code) {
							// if (data.data) {
							// this.excelData = data.data.data;
							// this.dialogUploadVisible = false;

							this.initData();
							this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
								type: 'success',
								//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
								callback: action => {
									// this.dialogFile = false;
									// this.initData();
									this.$emit('success');
								}
							});
							this.saveErr = false;

							this.dialogFile = false;
							// } else {
							//   this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
							//     type: 'warning'
							//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							//   });
							//   this.saveErr = true;
							//   // this.initData();
							// }
						} else {
							this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
								type: 'warning'
								//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							});
							this.saveErr = true;
							// this.initData();
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loadingUpload = false;
						this.saveErr = true;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					});
			},
			//删除
			delAction(event, row) {
				event.stopPropagation();
				this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
						// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
						type: 'warning'
					})
					.then(() => {
						// this.$message({
						//   type: 'success',
						//   message: '删除成功!'
						// });
						this.delData(this.$urlConfig.WhFileUploadDel + '/' + row.id, row);
					})
					.catch(() => {
						// this.$message({
						//   type: 'info',
						//   message: this.$t('i18nn_2e58cb9b52e2a214')
						// });
					});
			},
			//提交信息
			delData(url, formData) {
				this.loadingUpload = true;
				this.$http
					.delete(url, {})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						this.loadingUpload = false;
						if (200 == data.code) {
							this.getListData();
						} else {
							this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
								type: 'warning'
								//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loadingUpload = false;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					});
			},
			//查询 /wh/bizWhFile/query/{relationId}
			getListData() {
				this.loadingUpload = true;
				this.$http
					.get(this.$urlConfig.WhFileUploadList + '/' + this.relationId + '/' + this.typeCode, {})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_4195755cd53e871a'));
						console.log(data);
						this.loadingUpload = false;
						if (200 == data.code) {
							// if (data.rows) {
							this.tableData = data.rows;
							// } else {
							//   this.$alert('查询失败,请重试', this.$t('tips.tipsTitle'), {
							//     type: 'warning'
							//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							//   });
							// }
						} else {
							this.$alert(this.$t('tips.queryErrorFailed'), this.$t('tips.tipsTitle'), {
								type: 'warning'
								//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loadingUpload = false;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					});
			},
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_file_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_file_type = data.data['wh_file_type'];
			// 			} else {
			// 				// if (!data.msg) {
			// 				//   data.msg = this.$t("tips.submitError");
			// 				// }
			// 				// this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			// this.$message.error(this.$t("tips.submitRequestError"));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>